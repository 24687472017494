import Vue from 'vue'
import '@/lib/element-ui/theme/index.css'
import ElementUI from '@/lib/element-ui'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import animated from 'animate.css'
import store from './store'
import '../src/lib/font/font.css'
import Components from './components'
import util from '@/lib/util'
import { i18n } from '@/lib/i18n/index'
import ElementLocale from 'element-ui/lib/locale'

Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$util = util
Vue.use(ElementUI)
Vue.use(Components)
Vue.use(animated)

ElementLocale.i18n((key, value) => i18n.t(key, value))

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')