<template>
  <div class="pagination">
    <div class="page-info">
      <i
        class="icon-left"
        :class="[currentPage <= 1 ? 'not-allowed' : '']"
        @click="lastPage"
      ></i>
      <span class="pl10 pr10">{{ currentPage }}/{{ totalPage }}</span>
      <i
        class="icon-right"
        :class="[currentPage === totalPage ? 'not-allowed' : '']"
        @click="nextPage"
      ></i>
    </div>

    <div class="page-jumper">
      <input v-model="pageNum" @blur="validatePageNum" @keyup.enter="pageJump"/>
      <p @click="pageJump">{{$t('h.task.turnTo')}}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 每页显示条目个数
    pageSize: {
      type: Number,
      default: 10,
    },
    // 当前页数
    currentPage: {
      type: Number,
    },
    // 总条目
    total: {
      type: Number,
    },
  },

  computed: {
    // 总页数
    totalPage() {
      const page = Math.floor(this.total / this.pageSize);
      const divisible = this.total % this.pageSize;
      return divisible > 0 ? page + 1 : page;
    },
  },

  data() {
    return {
      pageNum: "",
    };
  },

  methods: {
    lastPage() {
      if (!this.totalPage) return;
      if (this.currentPage === 1) return;
      this.$emit("change-page", this.currentPage - 1);
    },

    nextPage() {
      if (!this.totalPage) return;
      if (this.currentPage === this.totalPage) return;
      this.$emit("change-page", this.currentPage + 1);
    },

    pageJump() {
      if (!this.totalPage) return;
      if (!/(^\d*$)/.test(this.pageNum)) return;

      if (this.pageNum > this.totalPage) {
        this.pageNum = this.totalPage;
      }
      if (this.pageNum <= 0) {
        this.pageNum = 1;
      }
      this.$emit("change-page", this.pageNum);
    },

    validatePageNum() {
      if (this.pageNum && !/(^\d*$)/.test(this.pageNum)) {
        this.pageNum = null;
        this.$message({
          message: "请输入数字",
          type: "error",
          offset: 80,
        });
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 32px;
  line-height: 28px;
  margin-left: 50px;

  .page-info {
    .icon-left, .icon-right {
      padding: 0 5px;
      color: #adadad;
      cursor: pointer;
    }
  }

  .page-jumper {
    width: 100px;
    height: 28px;
    line-height: 28px;
    display: flex;
    border-radius: 14px;
    border: 1px solid #ddd;
    font-size: 13px;
    text-align: center;
    margin-left: 20px;

    input {
      border: none;
      width: 38px;
      height: 21px;
      line-height: 21px;
      padding: 0;
      margin: 3px 5px;
      text-align: center;
    }

    input:focus {
      outline: none;
    }

    p {
      border-left: 1px solid #ddd;
      flex: 1;
      cursor: pointer;
    }
  }

  .not-allowed {
    cursor: not-allowed !important;
    opacity: 0.3;
  }
}
</style>
