import Vue from "vue"
import VueI18n from "vue-i18n"
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

Vue.use(VueI18n); // 全局挂载

export const i18n = new VueI18n({
  locale: localStorage.getItem("lang") || "en", // 从localStorage中获取 默认英文
  messages: {
    zh: {
      ...require("./lan/zh"),
      ...zhLocale
    }, // 中文语言包
    en: {
      ...require("./lan/en"),
      ...enLocale
    } // 英文语言包
  }
})

export default i18n