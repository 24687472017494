<template>
<div class="my-empty">
    <i class="icon-empty"></i>
    <p v-html="emptyText"></p>
</div>
</template>

<script>
export default {
    props: {
        emptyText: {
            type: String
        }
    }
}
</script>

<style lang="stylus" scoped>
.my-empty {
  position: relative;
  margin: 20% auto; 
  color: #ADADAD;
  font-size: 13px; 
  text-align: center; 
  i {
    color: #ff8c36;
    opacity: 0.3; 
    font-weight: 100;
    font-size: 60px;
  }
  p {
    margin-top: 10px;
    line-height: 24px;
  }
}
</style>
