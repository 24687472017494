import './rewrite.styl'
import {
  Badge,
  Button,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Col,
  DatePicker,
  Dialog,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Form,
  FormItem,
  Input,
  Loading,
  Menu,
  MenuItem,
  MenuItemGroup,
  Message,
  MessageBox,
  Notification,
  Option,
  Pagination,
  Progress,
  Radio,
  RadioGroup,
  RadioButton,
  Row,
  Select,
  Slider,
  Submenu,
  Tabs,
  TabPane,
  Table,
  TableColumn,
  Tooltip,
  Upload
} from 'element-ui'

function plugin (Vue) {
  Vue.component(Badge.name, Badge)
  Vue.component(Button.name, Button)
  Vue.component(Col.name, Col)
  Vue.component(Dialog.name, Dialog)
  Vue.component(Dropdown.name, Dropdown)
  Vue.component(DropdownMenu.name, DropdownMenu)
  Vue.component(DropdownItem.name, DropdownItem)
  Vue.component(Menu.name, Menu)
  Vue.component(Submenu.name, Submenu)
  Vue.component(MenuItem.name, MenuItem)
  Vue.component(MenuItemGroup.name, MenuItemGroup)
  Vue.component(Message.name, Message)
  Vue.component(MessageBox.name, MessageBox)
  Vue.component(Notification.name, Notification)
  Vue.component(DatePicker.name, DatePicker)
  Vue.component(Checkbox.name, Checkbox)
  Vue.component(CheckboxButton.name, CheckboxButton)
  Vue.component(CheckboxGroup.name, CheckboxGroup)
  Vue.component(Tabs.name, Tabs)
  Vue.component(TabPane.name, TabPane)
  Vue.component(Table.name, Table)
  Vue.component(TableColumn.name, TableColumn)
  Vue.component(Tooltip.name, Tooltip)
  Vue.component(Select.name, Select)
  Vue.component(Option.name, Option)
  Vue.component(Input.name, Input)
  Vue.component(Form.name, Form)
  Vue.component(FormItem.name, FormItem)
  Vue.component(Upload.name, Upload)
  Vue.component(Radio.name, Radio)
  Vue.component(RadioGroup.name, RadioGroup)
  Vue.component(RadioButton.name, RadioButton)
  Vue.component(Row.name, Row)
  Vue.component(Pagination.name, Pagination)
  Vue.component(Progress.name, Progress)
  Vue.component(Slider.name, Slider)
  Vue.use(Loading.directive)
  Vue.prototype.$loading = Loading.service
  Vue.prototype.$message = Message
  Vue.prototype.$msgbox = MessageBox
  Vue.prototype.$confirm = MessageBox.confirm
  Vue.prototype.$notify = Notification
}

export default plugin
