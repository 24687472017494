<template>
  <div id="app" ref="app" class="page-app">
    <div v-if="$route.path.includes('report') || $route.path === '/'" class='report-view' >
      <router-view :key="$route.path" />
    </div>

    <div class="is-login" v-else>
      <nav-bar></nav-bar>

      <div class="page-content">
        <side-bar></side-bar>

        <div class="router-view" :class="$route.path === '/' ? 'padding-0' : ''">
          <router-view :key="$route.path" />
        </div>
      </div>
    </div>

    <!-- <div v-if="$route.path !== '/'">
      <div v-show="showMask" class="tip-mask">
        <div class="update-tip" @dblclick="updateCount">
          <i class="el-icon-warning-outline pr10"></i>系统更新中,预计18:00前恢复使用,请谅解。(The system is being updated. )
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import NavBar from "views/layout/nav-bar";
import SideBar from "views/layout/side-bar";
import SetRem from '../src/lib/rem'

export default {
  components: {
    NavBar,
    SideBar,
  },

  data () {
    return {
      showMask: true,
      clickCount: 0
    }
  },

  watch:{
    $route: {
      handler (val) { 
        if (val.path !== '/' && !localStorage.getItem("token") && !location.href.includes('report')) {
          this.$router.push({
            path: "/"
          })
        }
        // 首页及报告页不需rem策略
        SetRem()
      },
      deep: true
    }
  },

  mounted () {
    if (!Date.now)
    Date.now = function() { return new Date().getTime() }
    this.fill()
    SetRem()
    if (localStorage.getItem("token")) {
      this.$store.commit('UPDATE_USER', { token: localStorage.getItem("token") })
    }
    this.handelRegion()
    // const hasPrompt = localStorage.getItem('hasPrompt')
    // if (!hasPrompt) {
    //   this.$confirm('PDM服务将于2022年1月6日下午7时-12时进行系统更新，期间将暂时关闭服务使用，请谅解。', '系统更新', {
    //     confirmButtonText: '确定',
    //     showCancelButton: false
    //   }).then(() => {
    //     localStorage.setItem('hasPrompt', true)
    //   })
    // }
    // 禁止缩放
    window.addEventListener('mousewheel', function(event){
      if (event.ctrlKey === true || event.metaKey) {
        event.preventDefault()
      }
    },{ passive: false})

    // firefox
    window.addEventListener('DOMMouseScroll', function(event){
      if (event.ctrlKey === true || event.metaKey) {
        event.preventDefault()
      }
    },{ passive: false})

    // 监听屏幕大小的变化
    window.addEventListener('resize', function () {
      setTimeout(() => {
        SetRem()
      })
    })
  },

  methods: {
    fill () {
      'use strict';
      let vendors = ['webkit', 'moz'];
      for (let i = 0; i < vendors.length && !window.requestAnimationFrame; ++i) {
        let vp = vendors[i];
        window.requestAnimationFrame = window[vp+'RequestAnimationFrame'];
        window.cancelAnimationFrame = (window[vp+'CancelAnimationFrame']
                                  || window[vp+'CancelRequestAnimationFrame']);
      }
      if (/iP(ad|hone|od).*OS 6/.test(window.navigator.userAgent) // iOS6 is buggy
        || !window.requestAnimationFrame || !window.cancelAnimationFrame) {
        let lastTime = 0;
        window.requestAnimationFrame = function(callback) {
            let now = Date.now();
            let nextTime = Math.max(lastTime + 16, now);
            return setTimeout(function() { callback(lastTime = nextTime); },
                              nextTime - now);
        };
        window.cancelAnimationFrame = clearTimeout;
      }
    },

    updateCount () {
      this.clickCount++
      if (this.clickCount > 2) {
        this.showMask = false
        this.clickCount = 0
      }
    },
    handelRegion(){
      // 获取国家配置
      if (localStorage.getItem("country")) {
        this.$store.commit('UPDATE_COUNTRY', { country: localStorage.getItem("country") })
      } else {
        localStorage.setItem("lang", "en")
      }
      if (location.hash.includes('?lang=')) {
        const query = location.hash.split('=')
        localStorage.setItem("lang", query[1])
      }
      this.$store.commit("UPDATE_USERTYPE", { usertype: localStorage.getItem("usertype")})
      // 获取语言配置
      this.$i18n.locale = localStorage.getItem("lang")
    }
  }
};
</script>

<style lang="stylus">
@require './lib/stylus/';
@require './lib/icomoon/';
@import './lib/css/base.css';

.page-app {
  height: 100%;
  position: relative;
}

.tip-mask {
  position: absolute;
  width: 100%; 
  height: 100%; 
  top: 0;
  left: 0;
  background: rgba(255,255,255,0.95);
  z-index: 1000;
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: center;

  .update-tip {
    font-size: 24px;
    font-weight: 600;
    color: #999;
    line-height: 30px;
    padding: 20px 0 40px 0;
  }
}

.is-login {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;
}

.page-content {
  display: flex;
  padding: 0 0 20px 0;
  flex: 1;
  height: 100%;
  overflow: hidden;
}

.router-view {
  flex: 1;
  margin-right: 30px;
  background-color: #fff;
  border: 1px solid #f5f6fa;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  padding: 24px 32px;
  flex-direction: column;
}
.report-view{
  height: 100%;
}
.padding-0 {
  padding: 0;
}
</style>
