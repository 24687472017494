import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// 自动导入 modules
const context = require.context('@/store', false, /\.js$/)
const modules = {}
context.keys().forEach(p => {
  modules[p.slice(2, -3)] = context(p).default
})


const store = new Vuex.Store({
  modules
})

export default store
