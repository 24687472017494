import MyPagination from './my-pagination'
import MyEmpty from './my-empty'
import AudioPlayer from './audio-player'

/**
 * 此处导出的组件，自动注册为全局组件
 */
const components = {
  MyPagination,
  MyEmpty,
  AudioPlayer
}

export default function plugin (Vue) {
  // 注册全局组件
  for (let id in components) {
    Vue.component(id, components[id])
  }
}
