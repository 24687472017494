import http from '@/lib/http'

export default {
    state: {},

    actions: {
        // 获取任务列表
        getTask(store, params) {
            return http.get('/web_copyright/mark/task', {
                params
            }).then(res => res.data)
        },

        // 删除任务
        delTask(store, params) {
            return http.delete(`/web_copyright/mark/task/${params.data_id}`, {
                params
            }).then(res => res.data)
        },

        // 新建任务，提交打分
        createdData(store, params) {
            return http.post('/web_copyright/mark/task', params).then(res => res.data)
        },

        getSingerpoolList(store, params) {
            return http.put('/web_copyright/mark/task', params).then(res => res.data)
        },
        
        // 获取COS相关的信息
        getCosInfo (store, params) {
            return http.post('/web_copyright/mark/cos_token', params).then(res => res.data)
        },
        
        // 获取已上传文件夹信息
        getUploadCosFiles (store, params) {
            return http.post('/web_copyright/mark/cos_file', params).then(res => res.data)
        },

        // 获取海外报告列表
        getReportList(store, params){
            return http.get('/web_copyright/report/all', {
                params
            }).then(res => res.data)
        },

        // 获取海外报告
        getReport(store, params){
            return http.get('/web_copyright/report/report', {
                params
            }).then(res => res.data)
        },
        // 获取 Focus Track Prediction
        getPrediction (store, params) {
            return http.post('/web_copyright/afrotunes/task', params).then(res => res.data)
        },
        // 获取海外报告
        getAfrotunesReport(store, params){
            return http.post('/web_copyright/mark/file/report', params).then(res => res.data)
        },
    }
}