import Vue from 'vue'
import http from '@/lib/http'

export default {
    state: {},

    mutations: {
        ['UPDATE_USER']: (state, data) => {
            if (data === null) {
                Object.keys(state).forEach(k => Vue.delete(state, k))
            } else {
                for (let k in data) {
                    Vue.set(state, k, data[k])
                }
            }
        },
        ['UPDATE_COUNTRY']: (state, data) => {
            if (data === null) {
                Object.keys(state).forEach(k => Vue.delete(state, k))
            } else {
                for (let k in data) {
                    Vue.set(state, k, data[k])
                }
            }
        },
        ['UPDATE_USERTYPE']: (state, data) => {
            if (data === null) {
                Object.keys(state).forEach(k => Vue.delete(state, k))
            } else {
                for (let k in data) {
                    Vue.set(state, k, data[k])
                }
            }
        }
    },

    actions: {
        login (store, params) {
            return http.get('/web_copyright/user/login', {params}).then(res => res.data)
        },

        register (store, params) {
            return http.post('/web_copyright/user/login', params).then(res => res.data)
        },
        inviteCode(store, params) {
            return http.post('/web_copyright/user/code', params).then(res => res.data)
        },
        // 获取打分额度
        getQuota(store, params){
            return http.get('/web_copyright/user/quota', {
                params
            }).then(res => res.data)
        },
        getLogo(store, params){
            return http.get('/web_copyright/user/logo', {
                params
            }).then(res => res.data)
        },
        uploadLogo(store, params){
            return http.post('/web_copyright/user/logo', params).then(res => res.data)
        },
        sendInfo (store, params) {
            return http.post('/web_copyright/user/message', params).then(res => res.data)
        }
    }
}
