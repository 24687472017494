import http from 'axios'
import router from '@/router'
import store from '@/store'
import {
  Message
} from "element-ui"


// 全局配置
Object.assign(http.defaults, {
  timeout: 5 * 60 * 1000
})

// 拦截请求
http.interceptors.request.use(
  function (config) {
    return config
  },
  function (error) {
    showInfo(localStorage.getItem("lang") === 'en' ? 'Bad Request' : '请求错误')
    console.log(error)
  }
)

const zhMsgMap = {
  1001: "请求参数缺失",
  1002: "请求参数不能为空",
  1003: "查询失败",
  1004: "删除失败",
  1005: "更新失败",
  1006: "添加失败",
  1007: "下载失败",
  1008: "请求参数格式错误",
  1111: "请求错误",
  1501: '请求错误：post public songPools: song_pool_id错误,歌曲池不存在,请检查参数',
  1502: '请求错误：post public songPools: song_id不存在,请检查参数',
  1503: '请求错误：post public songPools: 根据song_id获取link失败,请检查参数',
  1504: '请求错误：post public songPools: song_pool_id为非整数或缺失,请检查参数',
  1505: '请求错误：post public songPools: song_id下载失败',
  1506: '请求错误：post public songPools: song_id获取song_name和singer_name失败',
  2000: "请求成功",
  2001: "匹配未完成",
  2002: "歌词文件缺失",
  2003: "任务打分结束",
  2004: "任务结果查询失败",
  2005: "重试次数已达上限",
  2006: "开始抽取歌手池特征",
  3001: "登陆信息有误,请重新登陆",
  3002: "用户名或密码错误",
  3003: "该用户没有访问权限,请联系管理员开通!",
  3004: "用户已存在",
  3005: "用户不存在",
  3006: "邀请码不正确",
  3007: "用户权限不足",
  3008: "厂牌已存在",
  4001: "未找到相关业务",
  4002: "未找到相关任务",
  4003: "文件处理失败",
  4004: "没有该打分类型",
  4005: "没有该场景",
  4006: "文件名不合法",
  4007: "文件类型不支持",
  4008: "上传的文件为空",
  4009: "打分次数不足，如有疑问请联系商务",
  4010: "没有打分权限",
  4011: "未找到相关歌曲",
  4012: "没有上传歌曲",
  4013: "没有歌词",
  4014: "歌词文件错误",
  4015: "没有该歌手池权限",
  4016: "歌手池不存在",
  4017: "该歌手不存在",
  4018: "歌手池名称重复",
  4019: "账号权限已到期，如有疑问请联系商务",
  4020: "歌曲处理中...",
  4021: "歌曲处理失败",
  4022: "打分未完成",
  4023: "歌曲池不存在",
  4024: "输入不合法",
  5001: "发送失败"
}

const enMsgMap = {
  1001: "Requested parameter missing ",
  1002: "Requested parameter cannot be null ",
  1003: "Query failed ",
  1004: "Delete failed ",
  1005: "Update failed ",
  1006: "Add failed ",
  1007: "Download failed ",
  1008: "requested parameter format error ",
  1111: "Request error",
  2000: "Request success ",
  2001: "Matching not complete ",
  2002: "Lyrics file missing ",
  2003: "Task finished ",
  2004: "Task result query failed ",
  2005: "Retry times have reached the upper limit ",
  2006: "Start to extract the features of the singer pool ",
  3001: "Login information is incorrect, please login again ",
  3002: "Username or password is incorrect ",
  3003: "This account does not have access permission, please contact the administrator to open!",
  3004: "User already exists",
  3005: "User does not exist",
  3006: "Incorrect invitation code ",
  3007: "User permission is insufficient ",
  3008: "The label already exists ",
  4001: "Not found",
  4002: "Not found ",
  4003: "File processing failed ",
  4004: "There is no such rating type ",
  4005: "There is no such scene ",
  4006: "Invalid file name ",
  4007: "File type not supported ",
  4008: "Uploaded file is empty ",
  4009: "Insufficient credits, please contact PDM team if you have any questions ",
  4010: "No permission ",
  4011: "Not found ",
  4012: "No songs uploaded ",
  4013: "No lyrics ",
  4014: "Lyrics file error ",
  4015: "No permission for this singer pool ",
  4016: "Singer pool does not exist ",
  4017: "The singer does not exist ",
  4018: "Singer pool name is repeated ",
  4019: "Account permission has expired, if you have any questions, please contact PDM team",
  4020: "Song processing...",
  4021: "Song processing failed ",
  4022: "Task not completed ",
  4023: "Song pool does not exist ",
  4024: "Input does not conform to the format",
  5001: "Failed to send"
}  

// 拦截响应
http.interceptors.response.use(
  function (response) {
    const d = response.data
    // 全局处理请求成功，增加 success 字段
    if (d && d.code === 2000) {
      d.success = true
    } else if (d.code === 3001) {
      // 登录信息有误 返回登录页
      // showInfo(response.data.msg)
      localStorage.clear()
      // 开始用这个文件的时候需要使用下面这一句 
      store.commit('UPDATE_USER', null)
      router.push({
        path: "/"
      })
    } else if (d.msg) {
      d.msg = localStorage.getItem("lang") === 'en' ? enMsgMap[d.code] : zhMsgMap[d.code]
    }
    return response
  },
  function (err) {
    showInfo(err.response.data.msg ? err.response.data.msg : localStorage.getItem("lang") === 'en' ? 'System Error' : "系统错误")
  }
)

function showInfo(msg) {
  Message({
    message: msg,
    type: "error",
    offset: 80,
    duration: 1000
  })
}

export default http