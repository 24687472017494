import http from '@/lib/http'

export default {
    state: {},

    actions: {

        getSingerPool(store, params) {
            return http.get('/web_copyright/mark/singer_pool', {
                params
            }).then(res => res.data)
        },

        creatSingerPool(store, params) {
            return http.post('/web_copyright/mark/singer_pool',
                params
            ).then(res => res.data)
        },
        delSingerPool(store, data) {
            return http.delete('/web_copyright/mark/singer_pool', {
                data
            }).then(res => res.data)
        },
        getSingerList(store, params) {
            return http.get('/web_copyright/mark/singer', {
                params
            }).then(res => res.data)
        },
        addSinger(store, params) {
            return http.post('/web_copyright/mark/singer', params).then(res => res.data)
        },
        delSinger(store, data) {
            return http.delete('/web_copyright/mark/singer', {
                data
            }).then(res => res.data)
        },
    }
}