export const h = {
  // 首页
  home: {
    slogan: '寻找好音乐的智能帮手',
    audio: '音频',
    lyric: '歌词',
    description: '全球独家AI分析和预测技术',
    start: '开始打分',
    login: '登录/注册',
    copyRight: '腾讯音乐娱乐科技（深圳）有限公司 版权所有',
    nameMsg: '请输入您的名字',
    companyMsg: '请输入您的公司名称',
    emailMsg: '请输入您的邮箱地址',
    emailFormatMsg: '请确认您的邮箱格式',
    concactLater: '提交成功，请耐心等待我们的回复。',
    tryAgain: '操作过于频繁，请1分钟后重新输入表单。'
  },
  // 登录与注册
  account: {
    email: '请输入登录邮箱',
    password: '请输入登录密码',
    regist:'注册',
    placeRegist: '如无账号，请先',
    username: '请输入用户名',
    emailMsg: '请输入正确邮箱，用以接受打分结果',
    pswMsg: '请输入注册密码',
    pswAgainMsg: '请再次输入注册密码',
    inviteCodeMsg: '请输入邀请码',
    returnLogin: '返回登录',
    loginFailed: '用户名或密码错误',
    enterMail: '请输入邮箱！',
    enterPsw: '请输入密码！',
    mailFormat: '请检查邮箱格式！',
    loginSuccess: '登录成功'
  },
  // 侧边栏
  sideBar: {
    addTask: '新建任务',
    task: '任务管理',
    song: '歌曲管理',
    demo: 'Demo筛选',
    assess: '成品评估',
    singerPool: '歌手池'
  },
  // 顶部导航栏
  navBar:{
    invitationCode:'生成邀请码',
    reportManagement:'海外报告管理',
    toAccountInfo:'账户信息',
    signOut:'退出登录'
  },
  //账户信息
  accountInfo:{
    accountName:'账户名',
    logo:'公司标识',
    confirm:'确认',
  },
  // 任务管理
  task: {
    prompt: '提示',
    addTask: '新建任务',
    taskName: '任务名',
    releaseTime: '发布时间',
    status: '状态',
    trackNum: '歌曲数',
    check:'查看评分结果',
    prediction:'主打歌预测',
    scoreSence:'评分场景',
    operate: '操作',
    finished: '已完成',
    uploadFailed: '上传失败',
    expectFinishTime: '',
    expectFinishTime2: '分钟后完成',
    turnTo: '跳转',
    mutilFiles: '多文件上传',
    songQuota:'打分额度',
    zipFiles: '压缩上传',
    uploadTaskName:'任务名称',
    serviceModule:'服务模块',
    uploadFiles: '上传文件',
    wayToUpload: '上传方式',
    taskProperties :'任务属性',
    withoutPermission:'无权限',
    unlimited:'无限量',
    enterPlaceholder: '若为空系统会自动为数据命名',
    enterPlaceholder2: '请输入UPC，若为空系统会自动为数据命名',
    selectFiles: '选择文件',
    mutilFilesMsg: '只能上传格式为mp3/wav/txt/docx/doc的文件，可包含同名音频（.mp3/.wav）和歌词（.txt/.docx/.doc），单次上传歌曲不超过100首。',
    zipFilesMsg: '点击上传zip文件，可包含同名音频(.mp3/.wav)和歌词(.txt/.docx/.doc)，单次打分歌曲不超过100首',
    uploadToServer: '上传到服务器',
    uploadAgain: '重新上传',
    submit: '提交打分',
    reselectMsg: '歌词/音频文件已存在，请勿重复选择',
    formatMsg: '文件格式不符，请重新选择',
    unzipFile: '正在解压文件...',
    uploadingFile: '正在上传文件...',
    uploadFailMsg: '文件上传失败，请重新上传。',
    uploadSuccess:'上传成功',
    checkingMsg: '上传成功，正在校验文件',
    checkedMsg: '上传成功，已完成校验',
    checkedFailMsg: '文件校验失败，请重新上传。',
    afterSubmitMsg: '提交任务成功，打分完成后结果将发送到您的邮箱，也可在任务管理中自助查询',
    completeFormMsg: '请先完善表单后再提交打分',
    clearSongsMsg: '此操作将清空已上传歌曲，是否继续操作?',
    not_prediction: '歌曲数目不足',
    selectGenre: '选择流派',
    others: '其他'  
  },
  mark:{
    no:'序号',
    audio:'音频文件',
    lyric:'歌词文件',
    artist:'艺人名',
    artistC:'艺人国籍',
    operations:'操作',
    filemsg:'还未选择文件',
    countryMsg:'请为每一首歌曲选择国家'
  },
  // 歌曲管理
  song: {
    return: '返回',
    demo:'Demo筛选',
    assess:'成品评估',
    analysis:'歌曲分析',
    taskName: '任务名',
    scoreResult: '评分结果',
    releaseTime: '发布时间',
    onlyMarked: '只看我标记的',
    songName: '歌名',
    startTime:'开始时间',
    endTime:'结束时间',
    rank: '评级',
    score: '评分',
    play: '试听',
    more: '更多分析',
    aWeek:'最近一周',
    aMonth:'最近一个月',
    threeMonth:'最近三个月',
    all:'不限',
    taskNameMsg:'请输入任务名',
    taskNameMsg2:'请输入UPC',
    cancel:'取消',
    exportExcel:'导出Excel',
    exportPDF:'导出PDF',
    selectAll:'全选',
    empty:'清空',
    check:'查看',
    batchDownload:'批量操作',
    selectItems:'已选择',
    selectItems2:'项',
    dialogTab1:'多平台评分',
    dialogTab2:'画像分析',
    dialogTab3:'翻唱达人推荐',
    dialogTab4:'歌手推荐',
    dialogTab5:'相似歌曲',
    dialogTab6:'宣推建议',
    genderRatio:'性别占比',
    male:'男性',
    female:'女性',
    cityDistribution:'城市群分布',
    ageDistribution:'年龄群分布',
    singer:'歌手',
    referenceSong:'参考歌曲',
    referenceMsg:'以此歌曲参考歌手声线。',
    audition:'试听',
    feedback:'反馈',
    homepage:'歌手主页',
    quote:'报价(片段/全曲)',
    contact:'联系方式',
    feedbackMsg:'告诉我们这个推荐是否合适,',
    feedbackMsg1:'这将有助于我们提升推荐准确度。',
    tips:'排名不分先后',
    songPool:'歌曲池',
    songPoolTips:'爆款歌曲池包括：2年内QQ音乐日播峰值超过100w的歌曲',
    song:'歌曲',
    similarity:'相似度',
    similarityTips:'相似度>80%更具参考性',
    promoteTitle1:'适拍片段展示',
    promoteTitle2:'玩法推荐',
    promoteTitle3:'推广建议',
    footage:'适拍音频片段',
    type:'玩法类别:',
    promotionsText:'适合搭配',
    promotionsText1:'等类型的视频',
    unusual:'异常',
    emptyText: '暂无数据',
    emptyText2: '正在生成结果，请耐心等待。',
    emptyText3: '暂无匹配数据',
    tagFailed: '标记失败，请刷新网页重试',
    qqmusic_score: 'Q音评分',
    qqmusic_rank: 'Q音评分',
    kugoumusic_score: '酷狗评分',
    kugoumusic_rank: '酷狗评分'
  },  
  singerPool:{
    singerPool:'歌手池 ',
    delete:'删除',
    create:'新建',
    cancel:'取消',
    confirm:'确定',
    addSinger:'添加歌手',
    title1:'新建歌手池',
    title2:'新建歌手',
    delmsg:'确定删除该歌手吗？',
    delmsg2:'确定删除该歌手池吗？',
    singerPoolName:'歌手池名:',
    singerPoolNameMsg:'请输入歌手池名',
    singerUrl:'歌手链接:',
    singerUrlMsg:'请输入歌手url,一次输入多个请以逗号或者空格分隔',
    submit:'立即创建',
    tips:'* qq音乐网页版歌手主页链接，需以',
    tips2:'开头'
  },
  export: {
    title:'导出条件',
    msg1: '请先选择要下载结果的歌曲',
    msg2: '导出PDF报告时,请勿选择超过10首歌曲',
    msg3: '下载失败，请检查歌曲信息是否存在',
    msg4: '选择 [歌手推荐] 所属歌手池',
    msg5: '选择 [相似歌曲] 所属歌曲池'
    
  },
  Afrotunes: {
    delete: '删除歌曲',
    replaceL: '替换歌词',
    uploadL: '上传歌词',
    modify: '修改权限',
    msg1: '开启服务权限后将不能关闭该服务。请确认是否开启以下服务权限。',
    msg2: '此操作将影响后续结算。是否继续操作? ',
    msg3: '报告服务模块'
  },
  tool:{
    cancel:'取消',
    confirm:'确定',
    check:'查看详情',
    openArtistReport:'打开供艺人查看报告',
    openDSPReport:'打开供DSP查看报告',
    reportModules:'报告模块'
  }
}