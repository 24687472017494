import http from '@/lib/http'
import Vue from 'vue'

export default {
    state: {},

    mutations: {
        ['UPDATE_SONG']: (state, data) => {
            if (data === null) {
                Object.keys(state).forEach(k => Vue.delete(state, k))
            } else {
                for (let k in data) {
                    Vue.set(state, k, data[k])
                }
            }
        }
    },


    actions: {
        getSongs(store, params) {
            return http.get('/web_copyright/mark/song', {
                params
            }).then(res => res.data)
        },

        markSong(store, params) {
            return http.put('/web_copyright/mark/song', params).then(res => res.data)
        },

        donwloadSongs(store, params) {
            return http.get('/web_copyright/mark/file', {
                params
            }).then(res => res.data)
        },

        putFeedback(store, params) {
            return http.put('/web_copyright/mark/score', params).then(res => res.data)
        },
        
        getScore(store, params) {
            return http.get('/web_copyright/mark/score', {
                params
            }).then(res => res.data)
        },
        getAnalysisOverseas (store, params) {
            return http.post('/web_copyright/mark/score', params).then(res => res.data)
        },
        getSongLyric (store, params) {
            return http.get('/web_copyright/mark/song_info', {
                params
            }).then(res => res.data)
        },

        batchSongScore (store, params) {
            return http.get('/web_copyright/mark/score_list', {
                params
            }).then(res => res.data)
        },

        getSongPoolList (store, params) {
            return http.get('/web_copyright/mark/songPools', {params}).then(res => res.data)
        },

        getSimilarHotSong (store, params) {
            return http.post('/web_copyright/mark/songPools', params).then(res => res.data)
        },

        getSongPromotion(store, params) {
            return http.get('/web_copyright/mark/song_promotion', {params}).then(res => res.data)
        },

        getSingerMatch (store, params) {
            return http.post('/web_copyright/mark/singer_match', params).then(res => res.data)
        },

        exportPDF (store, params) {
            return http.post('/web_copyright/mark/file/pdf', params).then(res => res.data)
        },

        exportExcel (store, params) {
            return http.post('/web_copyright/mark/file/excel', params).then(res => res.data)
        },

        changePermission (store, params) {
            return http.post('/web_copyright/afrotunes/song', params).then(res => res.data)
        },

        getFocusTrackInfo (store, params) {
            return http.get('/web_copyright/afrotunes/key_song', {params}).then(res => res.data)
        },

        changeFocusTrackPermission (store, params) {
            return http.post('/web_copyright/afrotunes/key_song', params).then(res => res.data)
        }
    }
}