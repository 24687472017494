<template>
  <div>
    <el-row class="tac">
      <el-col>
        <el-menu
          :default-active="this.$route.path"
          class="el-menu-vertical-demo"
          text-color="#adadad"
          active-text-color="#ff8c36"
          router
          @select="handleSelect()"
        >
          <el-menu-item index="/task-list">
            <div class="title">
              <i class="icon-task"></i>
              <span>{{$t('h.sideBar.task')}}</span>
            </div>
          </el-menu-item>
          <el-submenu v-if="$store.state.user.country == 'China'" index="/song-list">
            <template slot="title">
              <div class="title" id="song_list_title">
                <i class="icon-songs" id="song_list"></i>
                <span>{{$t('h.sideBar.song')}}</span>
              </div>
            </template>
            <el-menu-item index="/song-list/demo">{{$t('h.sideBar.demo')}}</el-menu-item>
            <el-menu-item index="/song-list/assess">{{$t('h.sideBar.assess')}}</el-menu-item>
          </el-submenu>
          <el-menu-item v-if="$store.state.user.country == 'China'" index="/singer-pool">
            <div class="title">
              <i class="icon-singer"></i>
              <span slot="title">{{$t('h.sideBar.singerPool')}}</span>
            </div>
          </el-menu-item>
          <el-menu-item v-else index="/song-list/assess">
            <div class="title">
              <i class="icon-songs"></i>
              <span slot="title">{{$t('h.sideBar.song')}}</span>
            </div>
          </el-menu-item>
        </el-menu>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  beforeUpdate() {
    this.routeCheck();
  },
  methods: {
    routeCheck() {
      if(this.$store.state.user.country == 'China'){
        let i = document.getElementById("song_list");
        let title = document.getElementById("song_list_title");
      if (this.$route.path.includes("song-list")) {
        i.className = "active-icon icon-songs ";
        title.className = " active-icon title ";
      } else {
        i.className = "icon-songs";
        title.className = "title";
      }
      }
    },
    handleSelect(){
      sessionStorage.setItem('task-list-currentPage',1)
    }
  },
};
</script>
<style lang="stylus" scoped>
.tac {
  background-color: #fefefe;
  border-radius: 0px 20px 20px 0px;
  border: 1px;
  background-color: #fff;
  height: 100%;
  width: 220px;
  min-width: 12vw;
  overflow: hidden;
  margin-right: 20px;
}

.title {
  margin-left: 1vw;
  display: flex;
  align-items: center;

  i {
    margin-right: 5px;
    font-size: 18px;
  }
}

.active-icon {
  color: #ff8c36;
}

.is-active .title i {
  color: $primary;
}

::v-deep .el-menu {
  overflow: hidden;
  /* top: 30px; */
  display: flex;
  /* text-align: center; */
  /* border-radius: 0px 20px 0px 0px; */
  border: 1px;
  flex-direction: column;
  font-weight: 400;
  color: #1a1a1a;
  /* justify-content: center; */
}

::v-deep .el-menu--inline {
  margin-left: 3vw;
  text-align: left;
}

::v-deep .el-menu-item, ::v-deep .el-submenu {
  min-width: 10vw;
  font-size: 14px;
  margin-top: 1.5vh;
}

::v-deep .el-submenu__icon-arrow {
  top: 55%;
  right: 5%;
}

::v-deep .el-submenu__title {
  font-size: 14px;
}

::v-deep .el-submenu__icon-arrow {
  font-size: 14px;
  top: 50%;
  font-weight: 600;
}
</style>
