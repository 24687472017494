export const h = {
  home: {
    slogan: 'Forecast the next big hit',
    audio: 'Audio',
    lyric: 'Lyric',
    description: 'Global exclusive AI analysis and prediction technology',
    start: 'Start',
    login: 'Login/Sign-up',
    copyRight: 'Tencent Music Entertainment Group.All Rights Reserved.',
    nameMsg: 'Please enter your name.',
    companyMsg: 'Please enter the company name.',
    emailMsg: 'Please enter your email.',
    emailFormatMsg: 'Please check email format.',
    concactLater: 'Submit Success! Please wait for our reply.',
    tryAgain: 'Please try again after 1 minute.'
  },
  account: {
    email: 'Please enter your login Email address',
    password: 'Please enter your login Password',
    regist:'Sign Up',
    placeRegist: "Don't have an account yet?",
    username: 'Please enter your Username',
    emailMsg: 'Please enter the correct email to receive the report',
    pswMsg: 'Please enter your Password',
    pswAgainMsg: 'Please Confirm Password',
    inviteCodeMsg: 'Please enter the Invitation code',
    returnLogin: 'Return to the login',
    loginFailed: 'Incorrect username or password.',
    enterMail: 'Please enter your Email Address',
    enterPsw: 'Please enter your Password',
    mailFormat: 'Please check email format',
    loginSuccess: 'Login Success !'
  },
  sideBar: { 
    addTask: 'Add Task', 
    task: 'Task Management',
    song: 'Song Management',
    demo: 'Demo Scouting',
    assess: 'Track Assessment',
    singerPool: 'Artists Pool'
  },
  navBar:{
    invitationCode:'Invitation Code',
    reportManagement:'Report Management',
    toAccountInfo:'Account Info',
    signOut:'Sign Out'
  },
  accountInfo:{
    accountName:'Account Name',
    logo:'Company Logo',
    confirm:'Confirm',

  },
  task: {
    prompt: 'Prompt',
    addTask: 'New Task',
    taskName: 'Task Name',
    releaseTime: 'Report Time',
    status: 'Status',
    trackNum: 'No. of Tracks',
    check:'Check Details',
    prediction:'Focus Track Prediction',
    scoreSence:'Content Type',
    operate: 'Check Details',
    finished: 'Finished',
    uploadFailed: 'Upload Failed',
    expectFinishTime: 'Finish in',
    expectFinishTime2: 'minutes',
    turnTo: 'Turn To',
    mutilFiles: 'upload multiple files',
    songQuota:'Song Quota',
    zipFiles: 'upload zip files',
    uploadTaskName:'Task Name',
    serviceModule:'Service Module',
    taskProperties :'Content Type',
    withoutPermission:'Permission Denied',
    unlimited:'Unlimited',
    enterPlaceholder: 'Please enter a task name',
    enterPlaceholder2: 'Please enter UPC',
    wayToUpload: 'Way To Upload',
    uploadFiles: 'Upload Files',
    selectFiles: 'Select Files',
    mutilFilesMsg: 'Please upload files of maximum 100 songs in mp3/wav/txt/docx/doc format. Please ensure that the audio file and the lyrics file name are same.',
    zipFilesMsg: 'Click to upload zip files, including audio(.mp3/.wav). Available for maximum of 100 songs to upload at a time.',
    uploadToServer: 'Upload to the server',
    uploadAgain: 'Upload Again',
    submit: 'Submit',
    reselectMsg: ' lyrics/audio file already exists, please do not re-select.',
    formatMsg: 'The file format does not match. Please select a new one.',
    unzipFile: 'Decompressing files...',
    uploadingFile: 'Uploading files...',
    uploadFailMsg: 'File upload failed, please re-upload.',
    uploadSuccess:'Upload Success!',
    checkingMsg: 'Upload success! Checking files...',
    checkedMsg: 'Upload success!',
    checkedFailMsg: 'File checking failed. Please upload the file again.',
    afterSubmitMsg: 'You can check the report in Task Management.',
    completeFormMsg: 'Please complete the form before submitting.',
    clearSongsMsg: 'This operation will clear the uploaded songs. Do you want to continue?',
    not_prediction: 'Insufficient number of songs',
    selectGenre: 'Select Genre',
    others: 'Others'   
  },
  mark:{
    no:'NO.',
    audio:'Audio File',
    lyric:'Lyrics File',
    artist:'Artist Name',
    artistC:'Artist Country',
    operations:'Operations',
    filemsg:'No file has been selected',
    countryMsg:'Please select country for artist of each release.'

  },
  song: {
    return: 'Back',
    demo:'Song Management',
    assess:'Song Management',
    analysis:'Analysis For',
    taskName: 'Task Name',
    scoreResult: 'Score',
    releaseTime: 'Report Time',
    onlyMarked: 'Show Only Marked Items',
    songName: 'Track Name',
    startTime:'Start From',
    endTime:'End For',
    rank: 'Rank',
    score: 'Score',
    play: 'Play',
    more: 'More',
    aWeek:'Last Week',
    aMonth:'Last Month',
    threeMonth:'Last Three Months',
    all:'All',
    taskNameMsg:'Enter task name',
    taskNameMsg2:'Enter UPC',
    cancel:'Cancel',
    exportExcel:'Export Excel',
    exportPDF:'Export PDF',
    selectAll:'Select All',
    empty:'Clear All',
    check:'Check Details',
    batchDownload:'Batch Operation',
    selectItems:'',
    selectItems2:'Items Selected',
    dialogTab1:'Score',
    dialogTab2:'Audience Profile Analysis',
    dialogTab3:'Cover Artist',
    dialogTab4:'Artists Recommendation',
    dialogTab5:'Similar Tracks',
    dialogTab6:'Promotion Strategies',
    genderRatio:'Gender Ratio',
    male:'male',
    female:'female',
    cityDistribution:'Regional Distribution',
    ageDistribution:'Age Structure',
    singer:'Artists',
    referenceSong:'Reference Song',
    referenceMsg:"Take this track as vocal reference.",
    audition:'Play',
    feedback:'Feedback',
    homepage:'Artists Homepage',
    quote:'Quotation (clip/full song)',
    contact:'Contact Information',
    feedbackMsg:'Tell us if this recommendation is appropriate,',
    feedbackMsg1:'this will help us improve the recommendation accuracy.',
    tips:'Ranking in no particular order',
    songPool:'Content Pool',
    songPoolTips:'The hit songs pool includes: tracks with a daily peak of over 1M in 2 years on QQ Music',
    song:'Track',
    similarity:'similarity',
    similarityTips:'Similarity > 80% is more informative',
    promoteTitle1:'Catchy hook',
    promoteTitle2:'Reference videos',
    promoteTitle3:'Promotion Strategies',
    footage:'Suitable audio clips',
    type:'Theme:',
    promotionsText:'Perfect for',
    promotionsText1:'',
    unusual:'unusual',
    emptyText: 'No data available',
    emptyText2: 'Report generation in process. Please be patient.',
    emptyText3: "PDM doesn't find suitable TikTok campaign suggestions for the track yet.",
    tagFailed: 'Tag failed, please refresh the page and try again',
    qqmusic_score: 'QQMusic Score',
    qqmusic_rank: 'QQMusic Rank',
    kugoumusic_score: 'Kugou Score',
    kugoumusic_rank: 'Kugou Rank'
  },
  singerPool:{
    singerPool:'Artists Pool',
    delete:'Delete',
    create:'Add',
    cancel:'Cancel',
    confirm:'Confirm',
    addSinger:'Add artists',
    title1:'Create Artists Pool',
    title2:'Create Artist Profile ',
    delmsg:'Are you sure to delete the Artists ？',
    delmsg2:'Are you sure to delete the Artists Pool？',
    singerPoolName:'Artists Pool Name:',
    singerPoolNameMsg:'Please enter Artists Pool name',
    singerUrl:'Artists Url:',
    singerUrlMsg:'Please enter the Artists URL ,  Separate multiple entries with commas or Spaces',
    submit:'Submit',
    tips:'* Artists homepage URL from QQ Music web apps, start with',
    tips2:''
  },
  export: {
    title:'Export Basis',
    msg1: 'Please select the tracks to download the reports first.',
    msg2: 'Please do not select more than 10 tracks at a time when exporting PDF reports.',
    msg3: 'Download failed, please check whether the track information exists.',
    msg4: 'Select the artists pool to which [singer recommendation] belongs.',
    msg5: 'Select the content pool to which [similar tracks] belong.'
  },
  Afrotunes: {
    delete: 'Delete Track',
    replaceL: 'Replace Lyric',
    uploadL: 'Upload Lyric',
    modify: 'Modify Permission',
    msg1: 'After the service permission is enabled, the service cannot be disabled. Please check and confirm.',
    msg2: 'This operation will affect subsequent settlement. Do you want to continue?',
    msg3: 'Report Service Module'
  },
  tool:{
    cancel:'Cancel',
    confirm:'Confirm',
    check:'Check Details',
    openArtistReport:'Open Report For Artist',
    openDSPReport:'Open Report For DSP',
    reportModules:'Report Modules'
  }
}