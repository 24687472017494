import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/index')
  },
  {
    path: '/mark',
    name: 'mark',
    component: () => import('@/views/mark/index')
  },
  {
    path: '/task-list',
    name: 'task',
    component: () => import('@/views/task/index')
  },
  {
    path: '/song-list/demo',
    name: 'demo',
    component: () => import('@/views/song-list/index')
  },
  {
    path: '/song-list/assess',
    name: 'assess',
    component: () => import('@/views/song-list/index')
  },
  {
    path: '/singer-pool',
    name: 'singer-pool',
    component: () => import('@/views/singer-pool/index')
  },
  {
    path: '/report',
    name: 'report',
    component: () => import('@/views/report/index')
  },
  {
    path: '/report-a',
    name: 'report-a',
    component: () => import('@/views/report/afrotunes')
  },
  {
    path: '/report-d',
    name: 'report-d',
    component: () => import('@/views/report/afrotunes')
  },
  {
    path: '/report-prediction',
    name: 'report-prediction',
    component: () => import('@/views/task/focus-track-prediction.vue')
  },
  {
    path: '/management',
    name: 'report-management',
    component: () => import('@/views/report/management')
  },
  {
    path: '/account-info',
    name: 'account-info',
    component: () => import('@/views/account-info/index')
  },
]


export default new VueRouter({
  routes
})