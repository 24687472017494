<template>
  <div class="navbar">
    <div class="logo">
      <a @click="home()">
        <img src="~assets/home/logo-black.png" alt="" />
      </a>
    </div>
    <div class="version">
      <el-badge class="mr5" v-if="$store.state.user.country === 'China'" :is-dot="showNewVersion ? true : false">
        <i class="icon-bell ml15 fs18 pointer" @click="clickBadge"></i>
      </el-badge>
      <i class="fs18 ml5 mr10 pointer" :class="$i18n.locale === 'en' ? 'icon-zh' : 'icon-en'" @click="changeLanguage"></i>
      <el-dropdown>
        <span class="el-dropdown-link">
          {{ username }}
          <i class="el-icon-caret-bottom"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-if="showInviteDropdown" >
            <div class="dropdown-item" @click="showInviteCode=true"> {{$t('h.navBar.invitationCode')}}</div>
          </el-dropdown-item>
          <el-dropdown-item v-if="showInviteDropdown" >
            <div class="dropdown-item" @click="toManagement"> {{$t('h.navBar.reportManagement')}}</div>
          </el-dropdown-item>
          <el-dropdown-item>
            <div class="dropdown-item" @click="toAccountInfo()"> {{$t('h.navBar.toAccountInfo')}}</div>
          </el-dropdown-item>
          <el-dropdown-item>
            <div class="dropdown-item" @click="quit()"> {{$t('h.navBar.signOut')}}</div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-dialog 
      center
      title="获取邀请码"
      top="25vh"
      width="40%"
      :visible.sync="showInviteCode"
      :destroy-on-close="false">
      <div v-if="this.inviteCode">
        获取成功，邀请码为 {{this.inviteCode}} ,点击复制将复制邀请码。
      </div>
      <div v-else>
        <el-form  label-width="40%" style="width:90%">
        <el-form-item label="厂牌GroupName :" >
          <el-input v-model="groupName"></el-input>
        </el-form-item>
      </el-form>

      </div>
  <div v-if="!this.inviteCode" slot="footer" class="dialog-footer">
    <el-button @click="showInviteCode = false">取  消</el-button>
    <el-button type="primary" @click="getInviteCode">生  成</el-button>
  </div>
  <div v-else slot="footer" class="dialog-footer">
    <el-button @click="inviteCode = ''">重新生成</el-button>
    <el-button type="primary" @click="copyInviteCode">复 制</el-button>
  </div>
    </el-dialog >
    <el-dialog
      center
      title="版本更新"
      top="15vh"
      width="40%"
      :visible.sync="showVersionInfo"
      :destroy-on-close="false"
    >
      <div class="version-text">
        <p>版本1.6.2的新功能（发布于2022年4月18日）</p>
        <p>-导出PDF：新增相似歌曲、宣推建议模块</p>
        <p>-新建任务：展示demo、成品剩余打分额度</p>
        <p>-模型v2022.4.18</p>
        <br>
        <p>版本1.6.1的新功能（发布于2022年4月1日）</p>
        <p>-支持上传doc格式文件</p>
        <p>-优化文件命名容错规则</p>
        <br>
        <p>版本1.6.0的新功能（发布于2022年3月24日）</p>
        <p>-歌手推荐：【Demo筛选-更多分析-歌手推荐】支持切换歌手池，查询池内匹配的歌手</p>
        <p>-翻唱达人推荐：【成品评估-更多分析-翻唱达人推荐】支持切换歌手池，查询池内匹配的翻唱达人</p>
        <p>-歌手池：新增全民K歌歌手池</p>
        <p>-导出PDF：新增百分制分数展示</p>
        <br>
        <p>版本1.5.1的新功能（发布于2022年3月14日）</p>
        <p>-模型v2022.3.14</p>
        <br>
        <p>版本1.5.0的新功能（发布于2022年3月10日）</p>
        <p>-成品评估：新增宣推建议</p>
        <br>
        <p>版本1.4.1的新功能（发布于2022年3月7日）</p>
        <p>-多平台评分：成品评估新增酷狗平台评分</p>
        <br>
        <p>版本1.4.0的新功能（发布于2022年3月2日）</p>
        <p>-多平台评分：Demo筛选新增酷狗平台评分</p>
        <br>
        <p>版本1.3.5的新功能（发布于2022年2月23日）</p>
        <p>-模型v2022.2.23</p>
        <br>
        <p>版本1.3.4的新功能（发布于2022年2月17日）</p>
        <p>-模型v2022.2.17</p>
        <br>
        <p>版本1.3.3的新功能（发布于2022年1月25日）</p>
        <p>-成曲评估新增查询翻唱达人推荐功能</p>
        <br>
        <p>版本1.3.2的新功能（发布于2022年1月21日）</p>
        <p>-新增【成曲评估】功能：支持对成曲进行打分，提供歌曲受众画像分析</p>
        <p>-优化文件上传功能，支持多文件上传</p>
        <p>-模型v2022.1.21</p>
        <br>
        <p>版本1.3.1的新功能（发布于2022年1月6日）</p>
        <p>-Demo筛选新增查询相似歌曲功能，可在【Demo筛选-更多分析-相似歌曲】找到</p>
        <br>
        <p>版本1.3.0的新功能（发布于2021年12月14日）</p>
        <p>-支持自建专属歌手候选池：您可进入左侧导航栏的【歌手池】进行添加操作，完成后即可在提交打分任务时，自主选择任意歌手池</p>
        <p>-新增歌曲试听的播放控制</p>
        <p>-新增歌词内容预览</p>
        <p>-打分结果新增支持导出PDF报告，可在【打分结果页-批量下载】找到</p>
        <p>-模型v2021.12.14</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showNewVersion: true,
      showVersionInfo: false,
      showInviteCode:false,
      groupName:'',
      inviteCode:''
    }
  },
  computed: {
    username: function () {
      return localStorage.getItem("username");
    },
    showInviteDropdown:function () {
      return !location.host.includes('thepredictivemodel'&&'pdm.tencentmusic')
    },
  },
  mounted () {
    this.showNewVersion = localStorage.getItem('showVersion1.6.2') === 'false' ? false : true
  },
  methods: {
    changeLanguage () {
      const lan = this.$i18n.locale
      if (lan === 'zh') {
        localStorage.setItem("lang", "en")
        this.$i18n.locale = localStorage.getItem("lang")
      } else {
        localStorage.setItem("lang", "zh")
        this.$i18n.locale = localStorage.getItem("lang")
      }
    },

    clickBadge () {
      this.showNewVersion = false
      localStorage.setItem('showVersion1.6.2', false)
      this.showVersionInfo = true
    },

    async getInviteCode () {
      if(!this.groupName){
        this.$message({
          message:
            "请先输入厂牌GroupName后再点击生成邀请码",
          type: "error",
          offset: 80,
        });
        return
      }

      const res = await this.$store.dispatch("inviteCode", {
        token: localStorage.getItem("token"),
        group_name:this.groupName
      });
      if (res.code==2000){
        this.inviteCode = res.my_code
      }          
      else{
        this.$message({
          message:res.msg,
          type: "error",
          offset: 80,
        });
      }
    },
    copyInviteCode(){
      let input = document.createElement('textarea')
      input.value = this.inviteCode
      input.setAttribute('readonly', '')
      document.body.appendChild(input)
      input.select()
      if (document.execCommand('copy')) {
          document.execCommand('copy')
          this.$message({
              message: '复制成功',
              type: "success",
              offset: 100
          })
      }
      document.body.removeChild(input)
    },
    toManagement(){
      this.$router.push("/management");
    },
    home() {
      this.$router.push("/");
    },
    toAccountInfo(){
      this.$router.push("/account-info")
    },
    quit() {
      localStorage.clear();
      const route = this.$route.path;
      if (route.length > 1) {
        this.$router.push("/");
      }
      this.$store.commit("UPDATE_USER", null);
    },
  },
};
</script>

<style lang="stylus" scoped>
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 50px 12px 35px;
}

.logo img {
  width: 140px;
  cursor: pointer;
}

.dropdown-item {
  color:  #ff8c36;
  cursor: pointer;
}

.version {
  display: flex;
  align-items: center; 

  .version-box {
    position: relative;
    margin-right: 40px;
  }

  .update-text {
    cursor: pointer;
    color: #606266;
  }

  .update-text:hover {
    color: #999;
  }

  .new::after {
    content: 'new';
    position: absolute;
    top: -5px;
    right: -20px;
    background: #f56c6c;
    color: #fff;
    font-size: 8px;
    padding: 0 3px;
    height: 12px;
    line-height: 12px;
    border-radius: 10px;
  }
}
.version-text {
  height: 45vh;
  overflow: auto;
  line-height: 30px; 
  color: #666;
}
::v-deep .el-badge__content.is-dot {
  width: 6px;
  height: 6px;
}
</style>