<template>
<div class="audio-player">
    <p class="mp3-name ellipsis" :title="mp3Name">{{ mp3Name }}</p>
    <i v-if="isAudioPlay" class="icon-pause" @click="pauseMusic"></i>
    <i v-else class="icon-play" @click="playMusic"></i>
    <p class="time">{{ curTime }}</p>

    <!-- 进度条 -->
    <div class="progress-box">
        <div id="track" ref="track" class="progress-track">
            <!-- 总进度 -->
            <div class="play-bar-wrap" ref="progressBar">
                <!-- 缓存进度 -->
                <!-- <div class="buffer-bar" :style="{ transform: 'scaleX(' + bufferedScaleX + ')' }" ></div> -->
                <!-- 当前进度 -->
                <div class="progress-bar" :class="{ 'forbid-transition': thumbSlide }" :style="{ transform: 'scaleX(' + progressScaleX + ')' }"></div>
            </div>
            <!-- 进度按钮 -->
            <div ref="thumb" class="progress-thumb" :class="{ 'forbid-transition': thumbSlide }" :style="{ transform: 'translateX(' + thumbTranslateX + 'px)' }"></div>
        </div>
    </div>

    <p class="time">{{ totalTime }}</p>

    <audio id="audioPlayer" @canplay="getDuration" >
      <source :src="mp3Url" />
    </audio>
</div>
</template>

<script>
export default {
    props: {
        mp3Url: {
            type: String
        },
        mp3Name: {
            type: String,
            default: '暂无播放歌曲'
        }
    },

    data () {
        return {
            // 当前播放时间
            curTime: "00:00",
            // 音频总时长
            totalTime: "00:00",
            // 音频是否播放中
            isAudioPlay: false,
            // 音乐播放器
            audioPlayer: null,
            // 缓存进度
            bufferedScaleX: 0, 
            // 播放进度
            progressScaleX: 0, 
            // 音频条滑块的位置
            thumbTranslateX: 0, 
            // 进度条滑动时的标记 滑动时为true
            thumbSlide: false
        }
    },

    watch: {
        mp3Url (newVal, oldVal) {
            this.isAudioPlay = false
            this.audioPlayer.load()
            if (!oldVal) return
            setTimeout(() => {
                this.audioPlayer.play()
            }, 100)
        },

        isAudioPlay (val) {
            this.$emit('updateAudioStatus', val)
        }
    },

    mounted () {
        this.audioPlayer = document.getElementById("audioPlayer")
        this.isAudioPlay = !this.audioPlayer.paused
        this.slideProgress()
        this.audioInit()
        this.progressFn()
    },  

    methods: {
        // 获取音频时长（按秒计）
        getDuration() {
            // oncanplay 事件在用户可以开始播放视频/音频（audio/video）时触发 否则音频时长为NaN
            this.totalTime = this.$util.timeToString(this.audioPlayer.duration)
        },

        // 滑动封装
        slideProgress() {
            let audioPlayer = this.audioPlayer
            let thumb = this.$refs.thumb
            let _this = this

            thumb.onmousedown = function (e) {
                // 移动时暂停播放并设置transition: none，解决在滑动结束后出现回弹的bug
                audioPlayer.pause()
                _this.thumbSlide = true

                // 进度条长度
                let progressL = _this.$refs.track.offsetWidth
                let mouseInitX = e.clientX,
                mouseEndX = 0,
                moveX = 0,
                thumbInitX = _this.$util.stringToNum(thumb.style.transform),
                thumbEndX = 0

                document.onmousemove = function (obj) {
                    mouseEndX = obj.clientX
                    moveX = mouseEndX - mouseInitX

                    let a = thumbInitX - 0 + moveX
                    if (moveX > 0) {
                        thumbEndX = a > progressL ? progressL : a
                    } else {
                        thumbEndX = a <= 0 ? 0 : a
                    }

                    _this.progressScaleX = thumbEndX / progressL
                    _this.thumbTranslateX = thumbEndX
                }

                document.onmouseup = function () {
                    if (thumbEndX === 0) {
                        thumbEndX = thumbInitX
                    }

                    audioPlayer.currentTime = (thumbEndX * audioPlayer.duration) / progressL
                    setTimeout(() => {
                        audioPlayer.play()
                    })
                    _this.thumbSlide = false

                    document.onmousedown = null
                    document.onmousemove = null
                    document.onmouseup = null
                }
            }
        },

        // 音频初始化
        audioInit() {
            let _this = this
            let audioPlayer = this.audioPlayer

            // 监听音频可以开始播放,点击播放的时候触发
            audioPlayer.addEventListener("play", () => {
                this.isAudioPlay = true
            })

            // 监听音频暂停时的状态
            audioPlayer.addEventListener("pause", () => {
                this.isAudioPlay = false
            })

            // 当目前的播放位置已更改时触发事件 播放和调整时触发(进度条相关)
            audioPlayer.addEventListener("timeupdate", () => {
                // 获取进度条总长
                let progressLength = _this.$refs.track.offsetWidth
                // 当前播放到的位置（时长）调用封装的秒转字符串方法$util.timeToString
                _this.curTime = _this.$util.timeToString(audioPlayer.currentTime)

                // 记录当前播放记录的百分比
                let percent = audioPlayer.currentTime / audioPlayer.duration || 0
                // 记录当前播放进度
                _this.progressScaleX = percent.toFixed(3)

                // 当前缓存进度
                let buffered = audioPlayer.buffered.length ? audioPlayer.buffered.end(audioPlayer.buffered.length - 1) : 0
                _this.bufferedScaleX = (buffered / audioPlayer.duration).toFixed(3)

                // 当前进度的按钮位置
                _this.thumbTranslateX = (percent * progressLength).toFixed(3)
            })

            // 音频播放时长已改变
            audioPlayer.addEventListener("durationchange", () => {
                _this.fullTime = _this.$util.timeToString(audioPlayer.duration)
            })

            // 音频播放结束 控制播放类型
            audioPlayer.addEventListener(
                "ended",
                () => {
                    _this.thumbSlide = false
                    _this.isAudioPlay = false
                },
                true
            )
        },

        // 点击进度条修改播放进度
        progressFn() {
            let audioPlayer = this.audioPlayer
            let progress = this.$refs.progressBar
            // 进度条长度
            let _this = this

            progress.onmousedown = function () {
                // 防止thumb回弹
                audioPlayer.pause()
                _this.thumbSlide = true
            }

            progress.onmouseup = function (e) {
                // 进度条宽度
                let progressL = _this.$refs.track.offsetWidth
                // 进度条的位置
                let progressInit = _this.$refs.track.offsetLeft

                // 鼠标点击的位置
                let mouseInitX = e.clientX

                // 是进度条控制器滚动到鼠标点击的位置
                _this.thumbTranslateX = mouseInitX - progressInit
                _this.progressScaleX = (mouseInitX - progressInit) / progressL
                audioPlayer.currentTime = (_this.thumbTranslateX * audioPlayer.duration) / progressL

                setTimeout(() => {
                    audioPlayer.play()
                }, 100)
            }
        },

        // 播放音乐
        playMusic() {
            this.audioPlayer.play()
            this.isAudioPlay = true
        },

        // 暂停音乐
        pauseMusic() {
            if (!this.audioPlayer.paused) {
                this.audioPlayer.pause()
                this.isAudioPlay = false
            }
        }
    }
}
</script>

<style lang="stylus">
.audio-player {
    background: #F5F7FA;
    // flex: 1;
    display: flex;
    align-items: center;
    padding: 15px 20px;
    border-radius: 100px;

    .mp3-name {
        width: 160px;
        text-align: center;
    }

    i {
        padding: 0 10px;
        font-size: 18px;
        color: #abacaf;
    }

    .time {
        padding: 0 20px;
    }

    .progress-box {
        flex: 1;
    }

    .progress-track {
        width: 350px;
        height: 6px;
        position: relative;
    }

    .play-bar-wrap {
        border-radius: 6px;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        overflow: hidden;
        background: #e9ebec;
    }

    .buffer-bar {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
        transition: transform 0.5s linear, -webkit-transform 0.5s linear;
        background: #d7d7d7;
        opacity: 0.5;
    }

    .progress-bar {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: #d8d8d8;
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
        transition: transform 0.5s linear, -webkit-transform 0.5s linear;
    }

    .progress-thumb {
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #d8d8d8;
        top: -3px;
        left: -6px;
        cursor: pointer;
        transition: -webkit-transform 0.5s linear;
        transition: transform 0.5s linear;
        transition: transform 0.5s linear, -webkit-transform 0.5s linear;
    }

    .forbid-transition {
        transition: none !important;
    }
}
</style>