import user from './user'
import singer from './singer'
import song from './song'
import task from './task'

export default {
  user,
  singer,
  song,
  task
}