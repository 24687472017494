// Peomise 版延时
function sleep (ms = 0) {
    return new Promise(resolve => setTimeout(resolve, ms))
}

function _date (date) {
    if (!date) return new Date()
    if (typeof date === 'string') {
      return new Date(/(\d{4})\D?(\d{1,2})\D?(\d{1,2})/.exec(date).splice(1).join('/'))
    }
    else if (typeof date === 'number') {
      return new Date(date)
    }
    return date
}

// 格式化日期 yyyy-MM-dd
function formatDate (date, type = 'date', sep = '-') {
    date = _date(date)
    let str = ''
    if (type.startsWith('date')) {
      let y = date.getFullYear()
      let M = date.getMonth() + 1
      let d = date.getDate()
      if (M < 10) M = '0' + M
      if (d < 10) d = '0' + d
      str += y + sep + M + sep + d
    }
    if (type.endsWith('time')) {
      let h = date.getHours()
      let m = date.getMinutes()
      let s = date.getSeconds()
      if (h < 10) h = '0' + h
      if (m < 10) m = '0' + m
      if (s < 10) s = '0' + s
      if (str) str += ' '
      str += h + ':' + m + ':' + s
    }
    return str
}

// 将字符串转成数值
function stringToNum (str) {
  return Number(str.replace(/translateX\(|px\)/g, ""))
}

// 将秒数转化为字符串 格式00:00
function timeToString (param) {
  param = parseInt(param)
  let minute = ""
  let second = ""
  if (param >= 0 && param < 60) {
      param < 10 ? (second = "0" + param) : (second = param)
      return "00:" + second
  } else if (param >= 60) {
      minute = parseInt(param / 60)
      minute < 10 ? (minute = "0" + minute) : minute
      param - parseInt(minute * 60) < 10
      ? (second = "0" + String(param - parseInt(minute * 60)))
      : (second = param - parseInt(minute * 60))
      return minute + ":" + second
  }
}

// 分钟转秒
function minuteToSecond (min) {
  if (typeof min !== 'string') return 0
  const minArr = min.split(':')
  if (minArr.length !== 2) return 0
  const second = Number(minArr[0])*60 + Number(minArr[1])
  return second
}

/**
 * 把错误的数据转正
 * @param {number} num 数值
 * @param {number} precision 小数精度（默认 15）
 * @example
 *  strip(0.09999999999999998) === 0.1
 *  strip(0.1 + 0.2) === 0.3
 */
 function strip(num, precision = 12) {
  return +parseFloat(num.toPrecision(precision))
}

/**
 * 精确四舍五入
 * @param {number} num 数值
 * @param {number} precision 小数精度
 */
function round(num, precision = 2) {
  if (isNaN(num)) return 0
  const isNeg = num < 0 ? -1 : 1
  const absNum = Math.abs(num)
  const p = Math.pow(10, precision)
  return isNeg * Math.round(strip(absNum * p, 14)) / p
}

// 获取url参数
function getParams(url) {
  let res = {}
  if (url.includes('?')) {
    let str = url.split('?')[1]
    let arr = str.split('&')
    for (let item of arr) {
      let key = item.split('=')[0]
      let value = item.split('=')[1]
      res[key] = decodeURIComponent(value)
    }
  }
  return res
}

// 首字母改为大写
function getUpperCase (str) {
  return str.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase())
}

export default {
  sleep,
  formatDate,
  stringToNum,
  timeToString,
  minuteToSecond,
  round,
  getParams,
  getUpperCase
}