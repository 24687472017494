const baseSize = 12
// 设置 rem 函数

export default function SetRem () {
  let width = document.documentElement.clientWidth || document.body.clientWidth   
  let radtioWidth = width / 1300
  let fontSize = baseSize * radtioWidth * 0.9
  if (width < 1200) {
    fontSize = 10
  } 
  const needRem = window.location.hash !== '#/' && !window.location.href.includes('/report')
  if (needRem) {
    document.getElementsByTagName('html')[0].style['font-size'] = fontSize + 'px'
  } else {
    document.getElementsByTagName('html')[0].style['font-size'] = ''
  }
}